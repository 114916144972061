<template>
  <div>
    <div id="EmployeesAccount">
      <div class="EmployeesAccountTitle flexAndCenter">
        <div class="fontSize20 color333 fontWeightBold">{{ $fanyi("出入金详细") }}</div>
        <div class="headerBtnList flexAndCenter">
          <div class="flexAndCenterAndCenter" @click="downloadExcel">
            <img src="../../../../assets/churujinList/download.png" alt="">
            <div>エクセル導出</div>
          </div>
        </div>
      </div>
      <!--      出入金筛选-->
      <div class="flexAndCenter orderScreenContainer">
        <div class="orderKeywordsContainer flexAndCenter">
          <el-input v-model="form.keywords" style="width: 190px;" :placeholder="$fanyi('注文書/配送書/内容')"/>
        </div>
        <div class="orderTimeContainer flexAndCenter" style="margin-left:25px">
          <div class="fontSize14 color333">{{ $fanyi("时间") }}：</div>
          <el-date-picker v-model="form.start_time" type="datetime" @change="startTimeChange" style="width: 176px;"
                          :placeholder="$fanyi('开始时间')" format="yyyy-MM-dd HH:mm:ss"
                          value-format="yyyy-MM-dd HH:mm:ss" default-time="00:00:00"/>
          <div class="fontSize14 color333" style="width:43px;text-align: center">{{ $fanyi("至") }}</div>
          <el-date-picker v-model="form.end_time" type="datetime" @change="endTimeChange" style="width: 176px;"
                          :placeholder="$fanyi('结束时间')" format="yyyy-MM-dd HH:mm:ss"
                          value-format="yyyy-MM-dd HH:mm:ss" default-time="23:59:59"/>
        </div>
        <div class="orderSelectContainer flexAndCenter" style="margin-left:25px">
          <el-select v-model="form.bill_method" :placeholder="$fanyi('交易方式')"
                     style="width: 120px;margin-left: 16px">
            <el-option
                v-for="item in bill_method"
                :key="item.key"
                :label="item.value"
                :value="item.key">
            </el-option>
          </el-select>
        </div>
        <div class="orderScreenBtnListContainer flexAndCenter" style="margin-left:26px">
          <div @click="getData()">{{ $fanyi("搜索") }}</div>
          <div @click="reset()">{{ $fanyi("取消") }}</div>
        </div>
      </div>
      <!--      列表表头-->
      <div class="dataBody">
        <ul class="head flexAndCenter">
          <li class="oNO"><span class="tableHeadCon">No</span></li>
          <li class="startingTime">
            <span :title="$fanyi('交易生成时间')" class="tableHeadCon flexAndCenterAndCenter">
              <span style="display: inline-block;height: 56px;line-height: 56px">{{ $fanyi('交易生成时间') }}</span>
              <img src="../../../../assets/icon/sort.png" alt="" @click="updateGoodsSort" class="sortIcon">
            </span>
          </li>
          <li class="footnote">
            <span :title="$fanyi('注文書・配送書')" class="tableHeadCon"></span>
          </li>
          <li class="content">
            <span class="tableHeadCon" :title="$fanyi('内容')">{{ $fanyi("内容") }}</span>
          </li>
          <li class="Trading">
            <span class="tableHeadCon" :title="$fanyi('交易方式')">{{ $fanyi("交易方式") }}</span>
          </li>
          <li class="detail">
            <span class="tableHeadCon" :title="$fanyi('出金额')">{{ $fanyi("出金额") }}</span>
          </li>
          <li class="detail">
            <span class="tableHeadCon" :title="$fanyi('入金额')">{{ $fanyi("入金额") }}</span>
          </li>
          <li class="Trading">
            <span class="tableHeadCon" :title="$fanyi('残金')">{{ $fanyi("残金") }}</span>
          </li>
        </ul>
        <div>
          <div v-for="(newItem,newIndex) in newList" :key="newIndex">
            <ul :style="$route.query.order_sn==newItem.order_sn&&$route.query.serial_number_id==newItem.id?'background: #FCF2F2;':''"
                class="footer flexAndCenter">
              <!--            No-->
              <li class="oNO"><span class="tableHeadCon">{{ newIndex + 1 }}</span></li>
              <!--            交易生成时间-->
              <li class="startingTime">
                <span class="tableHeadCon">{{ newItem.created_at }}</span>
              </li>
              <!--            注文書・配送書-->
              <li :style="newItem.order_sn!==''?'flex-direction:row':''" class="footnote">
                <div class="footnoteContentContainer">
                  <div v-if="newItem.order_sn!==''" class="newTop">
                    <div>{{ newItem.order_sn.indexOf('P') !== -1 ? $fanyi('配送書') : $fanyi('注文書') }}</div>
                  </div>
                  <router-link v-if="newItem.order_sn.indexOf('P') === -1"
                               :to="{path:'/OrderDetails',query: {order_sn: newItem.order_sn}}"
                               class="colorB4272B cursorPointer" style="text-decoration: underline;"
                               target="_blank">
                    {{ newItem.order_sn }}
                  </router-link>
                  <router-link v-else :to="{path:'/deliveryDetails',query: {pOrder_sn: newItem.order_sn}}"
                               class="colorB4272B cursorPointer" style="text-decoration: underline;"
                               target="_blank">
                    {{ newItem.order_sn }}
                  </router-link>
                </div>
                <div v-show="newItem.orderDetailShow===false" class="showChildrenBtn"
                     @click="updateOrderDetailShowStatus(newItem)"><i class="el-icon-arrow-right"></i>
                </div>
                <div v-show="newItem.orderDetailShow===true" class="showChildrenBtn"
                     @click="updateOrderDetailShowStatus(newItem)"><i class="el-icon-arrow-down"></i>
                </div>
              </li>
              <!--            内容-->
              <li class="content">
                <div class="contentColumn flexAndCenter">
                  <el-popover
                      placement="bottom"
                      trigger="hover">
                    <div style="white-space: pre-wrap;" v-html="newItem.client_bill_reason"></div>
                    <div
                        slot="reference"
                        :style="newItem.bill_type==5||newItem.bill_type==10||newItem.bill_type==11||newItem.bill_type==51?'':'width: 100%'"
                        class="client_bill_reason_text u-line-3">
                      {{ $fanyi(newItem.client_bill_reason) }}
                    </div>
                  </el-popover>
                  <el-popover
                      v-if="(newItem.bill_type==5||newItem.bill_type==10||newItem.bill_type==11||newItem.bill_type==51)&&newItem.order_sn!==''&&newItem.sorting!='番号:'"
                      placement="right"
                      trigger="hover">
                    <div v-for="(adjust_detail_item,adjust_detail_index) in newItem.adjust_detail"
                         :key="adjust_detail_index">
                      <div v-for="(adjust_detail_item_value,adjust_detail_item_index) in adjust_detail_item"
                           :key="adjust_detail_item_index">
                        {{ adjust_detail_item_value }}
                      </div>
                    </div>
                    <div slot="reference" class="detailsText">
                      {{ newItem.sorting }}
                    </div>
                  </el-popover>
                  <div class="iconContainer">
                    <el-popover
                        v-if="(newItem.bill_type==5||newItem.bill_type==51)||((newItem.bill_type==5||newItem.bill_type==51)&&newItem.order_sn!=='')"
                        placement="right"
                        trigger="hover">
                      <div v-for="(adjust_detail_item,adjust_detail_index) in newItem.adjust_detail"
                           :key="adjust_detail_index">
                        <div v-for="(adjust_detail_item_value,adjust_detail_item_index) in adjust_detail_item"
                             :key="adjust_detail_item_index">
                          {{ adjust_detail_item_value }}
                        </div>
                      </div>
                      <div slot="reference" class="detailsText"
                           @click="$fun.routerToPage('/OrderDetails?type=1&order_sn=' + newItem.order_sn+'&serial_number_id='+newItem.id)">
                        {{ $fanyi('详细') }}
                      </div>
                    </el-popover>
                    <el-popover
                        v-if="(newItem.bill_type==10||newItem.bill_type==11)"
                        placement="right"
                        trigger="hover">
                      <div v-for="(adjust_detail_item,adjust_detail_index) in newItem.adjust_detail"
                           :key="adjust_detail_index">
                        <div v-for="(adjust_detail_item_value,adjust_detail_item_index) in adjust_detail_item"
                             :key="adjust_detail_item_index">
                          {{ adjust_detail_item_value }}
                        </div>
                      </div>
                      <div slot="reference" class="detailsText">
                        {{ $fanyi('详细') }}
                      </div>
                    </el-popover>
                    <div v-if="newItem.discount_use.length>0&&newItem.order_sn!==''">
                      <el-popover placement="bottom" trigger="hover">
                        <div v-for="(couponItem,couponIndex) in newItem.discount_use" :key="couponIndex">
                          <div>クーポン券利用：{{ couponItem.name_translation }}</div>
                        </div>
                        <img slot="reference" src="../../../../assets/discountCouponIcon.png" style="width: 20px"/>
                      </el-popover>
                    </div>
                  </div>
                </div>
              </li>
              <!--            交易方式-->
              <li class="Trading">
                {{ $fanyi(newItem.bill_method_name) }}
              </li>
              <!--            出金额-->
              <li class="detail">
                {{ newItem.bill_type_group !== '入金' ? newItem.amount : '' }}
              </li>
              <!--            入金额-->
              <li class="detail">
                {{ newItem.bill_type_group === '入金' ? newItem.amount : '' }}
              </li>
              <!--            残金-->
              <li class="Trading">
                {{ newItem.balance }}
              </li>
            </ul>
            <div v-show="newItem.orderDetailShow===true&&newItem.order_sn.indexOf('P') === -1" class="orderInfo">
              <div class="ordersListHeader">
                <div class="goodsNo">{{ $fanyi("番号") }}</div>
                <div class="goodsImage">{{ $fanyi("图片") }}</div>
                <div class="goodsAttribute">{{ $fanyi("商品属性") }}</div>
                <div class="purchaseData">現在</div>
                <div class="option">オプション</div>
                <div class="ordersNumber">{{ $fanyi("标签") }}</div>
                <div class="goodsRemark">{{ $fanyi("备注") }}</div>
              </div>
              <div>
                <div v-for="(goodsItem,goodsIndex) in newItem.orderDetailList" :key="goodsIndex"
                     class="ordersListFooter">
                  <div class="goodsNo">{{ goodsIndex + 1 }}</div>
                  <div class="goodsImage" style="flex-direction: column">
                    <el-popover placement="right" trigger="hover">
                      <el-image :src="goodsItem.pic"
                                style="width: 300px; height: 300px"
                      />
                      <el-image slot="reference"
                                :src="goodsItem.pic"
                                style="width: 60px;height: 60px"
                                @click="$fun.toCommodityDetails(goodsItem.goods_id,goodsItem.from_platform)"
                      />
                    </el-popover>
                    <div v-if="goodsItem.from_platform==1688" class="goodsType">1688</div>
                  </div>
                  <div class="goodsAttribute">
                    <el-popover v-if="goodsItem.detail!=null" placement="top" trigger="hover">
                      <div>
                        <div v-for="(detailItem,detailIndex) in goodsItem.detail" :key="detailIndex">
                          {{ detailItem.key }}：{{ detailItem.value }}
                        </div>
                      </div>
                      <div slot="reference" class="goodsAttributeDetailsContainer cursorPointer">
                        <div class="detailListContainer">
                          <div v-for="(detailItem,detailIndex) in goodsItem.detail" :key="detailIndex" class="u-line">
                            {{ detailItem.key }}：{{ detailItem.value }}
                          </div>
                        </div>
                        <div v-if="goodsItem.detail.length>3">........</div>
                      </div>
                    </el-popover>
                  </div>
                  <div class="purchaseData">
                    <div class="fontSize14">
                      <div>{{ $fanyi('数量') }}：{{ goodsItem.confirm_num }}</div>
                      <div>{{ $fanyi('单价') }}：{{ goodsItem.confirm_price }}</div>
                      <div>{{ $fanyi('运费') }}：{{ goodsItem.confirm_freight }}</div>
                    </div>
                  </div>
                  <div class="option">
                    <el-popover v-if="goodsItem.option!=null" placement="top" trigger="hover">
                      <div>
                        <div v-for="(optionItem,optionIndex) in goodsItem.option" :key="optionIndex">
                          {{ optionIndex + 1 }}.{{ optionItem.name_translate }}*{{ optionItem.num }}
                        </div>
                      </div>
                      <div slot="reference" class="goodsAttributeDetailsContainer cursorPointer">
                        <div class="optionListContainer">
                          <div v-for="(optionItem,optionIndex) in goodsItem.option" :key="optionIndex" class="u-line">
                            {{ optionIndex + 1 }}.{{ optionItem.name_translate }}*{{ optionItem.num }}
                          </div>
                        </div>
                        <div v-if="goodsItem.option.length>3">........</div>
                      </div>
                    </el-popover>
                  </div>
                  <div class="ordersNumber">
                    <el-popover v-if="goodsItem.order_detail_tag!=undefined" placement="top" trigger="hover">
                      <div>
                        <div v-for="(tagItem,tagIndex) in goodsItem.order_detail_tag" :key="tagIndex">
                          <div>{{ tagItem.type_translate }}</div>
                          <div>{{ tagItem.no }}</div>
                          <div>{{ tagItem.goods_no }}</div>
                        </div>
                      </div>
                      <div slot="reference">
                        <div class="goodsTagDetailsContainer cursorPointer">
                          <div v-for="(tagItem,tagIndex) in goodsItem.order_detail_tag" :key="tagIndex">
                            <div class="u-line">{{ tagItem.type_translate }}</div>
                            <div class="u-line">{{ tagItem.no }}</div>
                            <div class="u-line">{{ tagItem.goods_no }}</div>
                          </div>
                        </div>
                        <div v-if="goodsItem.order_detail_tag.length>1">........</div>
                      </div>
                    </el-popover>
                  </div>
                  <div class="goodsRemark fontSize14">
                    <div :title="goodsItem.client_remark" class="u-line-5" style="width:202px">
                      {{ goodsItem.client_remark }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-show="newItem.orderDetailShow===true&&newItem.order_sn.indexOf('P') !== -1" class="porderInfo">
              <div class="ordersListHeader">
                <div class="ordersNumber">{{ $fanyi("订单号") }}</div>
                <div class="goodsNo">{{ $fanyi("番号") }}</div>
                <div class="goodsImage">{{ $fanyi("图片") }}</div>
                <div class="goodsAttribute">{{ $fanyi("商品属性") }}</div>
                <div class="purchaseData">{{ $fanyi("购买数据") }}</div>
                <div class="quantityShipped">{{ $fanyi("发货数") }}</div>
                <div class="option">オプション</div>
                <div class="ordersNumber">{{ $fanyi("标签") }}</div>
                <div class="goodsRemark">{{ $fanyi("备注") }}</div>
              </div>
              <div>
                <div v-for="(orderItem, orderIndex) in newItem.orderDetailList" :key="orderIndex"
                     class="ordersListFooter">
                  <div class="ordersNumber">
                    <router-link :to="{ path: '/OrderDetails', query: { order_sn: orderItem.order_sn } }"
                                 class="colorB4272B cursorPointer fontSize14" style="text-decoration: underline;"
                                 target="_blank">
                      {{ orderItem.order_sn }}
                    </router-link>
                  </div>
                  <div class="goodsNo">{{ orderItem.sorting }}</div>
                  <div class="goodsImage">
                    <el-popover placement="right" trigger="hover">
                      <el-image :src="orderItem.pic" style="width: 300px; height: 300px"/>
                      <el-image slot="reference" :src="orderItem.pic" style="width: 60px;height: 60px"
                                @click="$fun.toCommodityDetails(orderItem.goods_id, orderItem.from_platform)"/>
                    </el-popover>
                  </div>
                  <div class="goodsAttribute">
                    <el-popover placement="top" trigger="hover">
                      <div>
                        <div v-for="(detailItem, detailIndex) in orderItem.detail" :key="detailIndex">
                          {{ detailItem.key }}：{{ detailItem.value }}
                        </div>
                      </div>
                      <div slot="reference" class="goodsAttributeDetailsContainer cursorPointer">
                        <div v-for="(detailItem, detailIndex) in orderItem.detail" :key="detailIndex" class="u-line">
                          {{ detailItem.key }}：{{ detailItem.value }}
                        </div>
                      </div>
                    </el-popover>
                  </div>
                  <div class="purchaseData">
                    <div class="fontSize14">
                      <div>{{ $fanyi('数量') }}：{{ orderItem.confirm_num }}</div>
                      <div>{{ $fanyi('单价') }}：{{ orderItem.confirm_price }}</div>
                      <div>{{ $fanyi('运费') }}：{{ orderItem.confirm_freight }}</div>
                    </div>
                  </div>
                  <div class="quantityShipped fontSize14">{{ orderItem.porder_send_num }}</div>
                  <div class="option">
                    <el-popover v-if="orderItem.option != null && orderItem.option.length > 0" placement="top"
                                trigger="hover">
                      <div>
                        <div v-for="(optionItem, optionIndex) in orderItem.option" :key="optionIndex">
                          {{ optionIndex + 1 }}.{{ optionItem.name_translate }}*{{ optionItem.num }}
                        </div>
                      </div>
                      <div slot="reference" class="goodsAttributeDetailsContainer cursorPointer">
                        <div class="optionListContainer">
                          <div v-for="(optionItem, optionIndex) in orderItem.option" :key="optionIndex" class="u-line">
                            {{ optionIndex + 1 }}.{{ optionItem.name_translate }}*{{ optionItem.num }}
                          </div>
                        </div>
                        <div v-if="orderItem.option.length > 3">........</div>
                      </div>
                    </el-popover>
                  </div>
                  <div class="ordersNumber">
                    <el-popover v-if="orderItem.porder_detail_tag != null && orderItem.porder_detail_tag.length > 0"
                                placement="top"
                                trigger="hover">
                      <div>
                        <div v-for="(tagItem, tagIndex) in orderItem.porder_detail_tag" :key="tagIndex">
                          <div>{{ tagItem.type_translate }}</div>
                          <div>{{ tagItem.no }}</div>
                          <div>{{ tagItem.goods_no }}</div>
                        </div>
                      </div>
                      <div slot="reference">
                        <div class="goodsTagDetailsContainer cursorPointer">
                          <div v-for="(tagItem, tagIndex) in orderItem.porder_detail_tag" :key="tagIndex">
                            <div class="u-line">{{ tagItem.type_translate }}</div>
                            <div class="u-line">{{ tagItem.no }}</div>
                            <div class="u-line">{{ tagItem.goods_no }}</div>
                          </div>
                        </div>
                        <div v-if="orderItem.porder_detail_tag.length > 1">........</div>
                      </div>
                    </el-popover>
                  </div>
                  <div class="goodsRemark fontSize14">
                    <div :title="orderItem.client_remark" class="u-line-5 cursorPointer">{{
                        orderItem.client_remark
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="padding: 30px 0;justify-content: center" class="flexAndCenter pagination">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="form.page"
            :page-sizes="[10,20,30,40,60,100]"
            :page-size="form.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import websiteUrl from "@/api/wangZhi";

export default {
  data() {
    return {
      form: {
        start_time: '',//开始时间
        end_time: '',//结束时间
        keywords: '',//关键词
        bill_type: '',//账单类型
        bill_method: '',//交易方式
        order_by: 'desc',
        page: 1,//当前页
        pageSize: 10,//当前页多少条数据
      },
      bill_method: [],//交易方式
      bill_type: [],//账单类型
      total: 0,
      tableData: [],
      newList: [],
      timeValue: [],
      orderList: [],
    };
  },
  computed: {},
  created() {
    if (this.$route.query.order_sn != undefined) {
      this.form.keywords = this.$route.query.order_sn;
    }
    this.getData();
    this.getBalanceChangeTypes();
  },
  methods: {
    // 获取出入金数据
    getData() {
      this.$api.capitalBillList(this.form).then((res) => {
        if (res.code != 0) {
          return
        }
        res.data.data.forEach((item) => {
          if ((item.bill_type == 5 || item.bill_type == 51) && item.order_sn !== '') {
            item.sorting = '番号:';
            item.adjust_detail.forEach((adjust_detail_item, adjust_detail_item_index) => {
              let list = adjust_detail_item[0].split(" ");
              if (list[1] != undefined) {
                let index = list[1].indexOf('番号:');
                if (adjust_detail_item_index === item.adjust_detail.length - 1 && list[1] != undefined) {
                  item.sorting += list[1].substr(index + 3, list[1].length)
                } else {
                  item.sorting += list[1].substr(index + 3, list[1].length) + '、'
                }
              }
            })
          }
          if (item.order_sn !== '') {
            item.orderDetailShow = false;
            item.orderDetailList = [];
          }
        })
        this.newList = res.data.data;
        this.total = res.data.total;
      })
    },
    //获取出入金类型&&交易方式数据
    getBalanceChangeTypes() {
      this.$api.getBalanceChangeTypes().then((res) => {
        this.bill_method = res.data.bill_method;
        this.bill_type = res.data.bill_type;
      })
    },
    updateGoodsSort() {
      this.form.order_by === 'asc' ? this.form.order_by = 'desc' : this.form.order_by = 'asc';
      this.getData(this.form);
    },
    //设置每页多少条
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.getData()
    },
    //设置当前多少页
    handleCurrentChange(val) {
      this.form.page = val;
      this.getData()
    },
    startTimeChange() {
      if (this.form.end_time !== '') {
        if (this.form.end_time < this.form.start_time) {
          this.$message.error(this.$fanyi("开始时间不能晚于结束时间"));
          this.form.start_time = '';
        }
      }
    },
    //判断结束时间不能早于开始时间
    endTimeChange() {
      if (this.form.start_time !== '') {
        if (this.form.start_time > this.form.end_time) {
          this.$message.error(this.$fanyi("结束时间不能早于开始时间"));
          this.form.end_time = '';
        }
      }
    },
    //重置筛选项
    reset() {
      this.form = {
        start_time: '',//开始时间
        end_time: '',//结束时间
        keywords: '',//关键词
        bill_type: '',//账单类型
        bill_method: '',//交易方式
        page: 1,//当前页
        pageSize: 10,//当前页多少条数据
      };
    },
    updateOrderDetailShowStatus(item) {
      item.orderDetailShow = !item.orderDetailShow;
      if (item.orderDetailList.length === 0) {
        this.$api.capitalBillListGetOrderPorderDetail({
          order_sn: item.order_sn
        }).then((res) => {
          item.orderDetailList = res.data;
          this.$forceUpdate();
        })
      }
    },
    //下载账单excel
    downloadExcel() {
      if (this.form.end_time == '' || this.form.start_time == '') {
        this.$message.error(this.$fanyi("请先选择要导出的时间范围"))
        return
      }
      this.$api.getUserBillDownloadToken().then((res) => {
        const a = document.createElement('a')
        a.href = `${websiteUrl}/client/download.userBillDownload?user_id=${this.$store.state.userInfo.operation_id}&dateStart=${this.form.start_time}&dateEnd=${this.form.end_time}&downloadToken=${res.data.downloadToken}`
        a.target = '_blank';
        document.body.appendChild(a)
        a.click()
      });
    }
  },
};
</script>
<style lang='scss' scoped>
@import "../../../../css/mixin.scss";

/deep/ input[type="checkbox"]:checked {
  background: #4A91E9;
}

.showChildrenBtn {
  height: 30px;
  background: #F0F0F0;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  cursor: pointer;

  .el-icon-arrow-right:before, .el-icon-arrow-down:before {
    font-weight: bold;
    font-size: 14px;
    padding-left: 0 !important;
  }

  div {
    color: #333333;
    font-size: 14px;
  }
}

.tableDataNullContainer {
  height: 100px;
  line-height: 100px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

#EmployeesAccount {
  background: #ffffff;
  border-radius: 10px;

  .EmployeesAccountTitle {
    background: #FFFFFF;
    height: 73px;
    justify-content: space-between;
    padding: 0 26px;
    border-bottom: 1px solid #DFDFDF;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .headerBtnList {
      div {
        width: 100px;
        height: 34px;
        background: #B4272B;
        border-radius: 6px;
        line-height: 34px;
        text-align: center;
        cursor: pointer;
        color: #fff;
        font-size: 12px;
      }

      img {
        width: 14px;
        height: 14px;
        margin-left: 6px;
      }
    }
  }

  .ordersListHeader {
    display: flex;
    background: #F9F9F9;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    div {
      color: #333333;
      font-weight: bold;
      font-size: 14px;
      text-align: center;
      height: 42px;
      line-height: 42px;
    }
  }

  .orderInfo {
    .goodsNo {
      width: 80px;
    }

    .goodsAttributeDetailsContainer {
      .optionListContainer, .detailListContainer {
        width: 119px;
        height: 56px;
        overflow: hidden;
      }

      div {
        text-align: left;
        color: #333333;
        font-size: 14px;
        width: 119px;
      }
    }

    .goodsImage {
      width: 100px;
    }

    .goodsAttribute {
      width: 160px;
    }

    .purchaseData {
      width: 160px;
    }

    .ordersNumber {
      width: 200px;

      .goodsTagDetailsContainer {
        width: 172px;
        height: 56px;
        overflow: hidden;

        div {
          text-align: left;
          color: #333333;
          font-size: 14px;
          width: 172px;
        }
      }
    }

    .option {
      width: 200px;
    }

    .goodsRemark {
      width: 202px;
    }
  }

  .porderInfo {
    .ordersNumber {
      width: 180px;

      .goodsTagDetailsContainer {
        width: 172px;
        height: 53px;
        overflow: hidden;

        div {
          text-align: left;
          color: #333333;
          font-size: 14px;
          width: 172px;
        }
      }
    }

    .goodsNo {
      width: 50px;
    }

    .goodsImage {
      width: 88px;
    }

    .goodsAttribute {
      width: 150px;
    }

    .goodsAttributeDetailsContainer {
      width: 119px;
      height: 48px;

      .optionListContainer,
      .detailListContainer {
        width: 119px;
        height: 56px;
        overflow: hidden;
      }

      div {
        text-align: left;
        color: #333333;
        font-size: 14px;
        width: 119px;
      }
    }

    .purchaseData {
      width: 120px;
    }

    .quantityShipped {
      width: 80px;
    }

    .option {
      width: 140px;
    }

    .goodsRemark {
      width: 114px;
    }
  }

  .ordersListFooter {
    display: flex;
    background: #FFF8EE;
    height: 120px;

    .ordersNumber, .goodsNo, .goodsImage, .goodsAttribute, .purchaseData, .quantityShipped, .option, .ordersNumber, .goodsRemark {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .orderScreenContainer {
    margin-left: 26px;
    padding: 15px 0;
    margin-right: 26px;
    border-bottom: 1px solid #DFDFDF;
    justify-content: space-between;

    .orderKeywordsContainer {
      /deep/ .el-input__inner {
        width: 190px;
        height: 30px;
        background: #FFFFFF;
        border: 1px solid #DFDFDF;
        border-radius: 6px;
      }
    }

    .orderTimeContainer {
      /deep/ .el-input__inner {
        width: 176px;
        height: 30px;
        background: #FFFFFF;
        border: 1px solid #DFDFDF;
        border-radius: 6px;
        padding-left: 13px;
        padding-right: 0;
      }

      /deep/ .el-date-editor {
        .el-input__prefix {
          display: none;
        }
      }

      /deep/ .el-input__icon {
        line-height: 30px;
      }
    }

    .orderSelectContainer {
      /deep/ .el-input__inner {
        width: 120px;
        height: 30px;
        background: #FFFFFF;
        border: 1px solid #DFDFDF;
        border-radius: 6px;
        padding-left: 13px;
        padding-right: 0;
      }

      /deep/ .el-input__icon {
        line-height: 30px;
      }
    }

    .orderScreenBtnListContainer {
      div {
        width: 76px;
        height: 30px;
        //background: #B4272B;
        border-radius: 6px;
        cursor: pointer;
        text-align: center;
        font-size: 12px;
        line-height: 30px;
      }

      div:first-child {
        color: #fff;
        background: #B4272B;
        margin-right: 10px;
      }

      div:last-child {
        border: 1px solid #B4272B;
        color: #B4272B;
      }
    }

  }

  .dataBody {
    margin: 20px 26px 0;

    .oNO {
      width: 40px;
      min-width: 40px;
    }

    .startingTime {
      width: 116px;
      min-width: 116px;
      text-align: center;

      .sortIcon {
        cursor: pointer;
        width: 20px;
        margin-left: 20px;
      }
    }

    .footnote {
      width: 210px;
      max-width: 210px;
      display: flex;
      flex-direction: column;

      .footnoteContentContainer {
        padding-left: 12px;
        width: 165px;

        .pageJump {
          cursor: pointer;
          display: flex;
          justify-content: flex-start;
          text-decoration: underline;
          color: #B4272B;
        }

        .newTop {
          display: flex;
          justify-content: flex-start;
          width: 100%;

          img {
            width: 12px;
            height: 18px;
            margin-left: 10px;
          }
        }
      }

    }

    .content {
      width: 290px;

      .contentColumn {
        width: 100%;
        justify-content: space-between;
        padding: 0 10px 0 15px;

        .iconContainer {
          width: 28px;
          display: flex;
          justify-content: flex-start;
        }

        .detailsText {
          font-size: 14px;
          color: #B4272B;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .icon1 {
        width: 18px;
        height: 22px;
      }
    }

    .billType {
      width: 110px;
    }

    .Trading {
      width: 90px;
    }

    .detail {
      width: 126px;
    }

    .hoverText {
      color: #B4272B;
      text-decoration: underline;
      cursor: pointer;
    }

    .goodsImg {
      width: 100px;
    }

    .remarks {
      width: 122px;
    }

    .goodsAttribute {
      width: 200px;

      .showDetailCon {
        width: 200px;
        text-align: center;

        .ispay {
          width: 200px;
          height: 20px;
          line-height: 20px;
        }
      }
    }

    .newHead {
      background: #fff;
      height: 60px;
      font-size: 14px;

      .orderNumber {
        width: 360px;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
      }

      .goodsImg {
        text-align: center;
      }

      .newGodsImg {
        width: 100px;
        text-align: center;
        margin-right: 122px;
      }

      .subtotal {
        text-align: center;
      }

      .goodsAttribute {
        text-align: center;
        cursor: pointer;
      }
    }

    .subtotal {
      width: 120px;
    }

    .upLoad {
      width: 90px;
    }

    .client_bill_reason_text {
      width: 150px;
    }

    .orderStatus {
      width: 140px;
    }

    .operation {
      width: 112px;
    }

    .head {
      justify-content: center;
      background: #F0F0F0;
      font-size: 14px;
      color: #333;
      height: 56px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      text-align: center;
    }

    .footer {
      li {
        display: flex;
        height: 70px;
        min-height: 70px;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #333333;
        border-bottom: 1px solid #DFDFDF;
      }
    }

    .footer:last-child {
      //border-radius: 10px 10px 0px 0px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      overflow: hidden;
    }

    .footer:first-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      overflow: hidden;
    }


    .obody {
      text-align: center;
      height: 70px;
      font-size: 14px;
      border-bottom: 1px solid #DFDFDF;
    }
  }
}

.pagination {
  /deep/ .el-pagination .el-select .el-input {
    width: 110px;
  }
}
</style>
